import React from "react"

export default function() {
  return (
    <div className="container mt-5 pt-5 text-center">
      <h1>Uh Oh! This page doesn't exist</h1>
      <a href="/" className="text-decoration-none">
        Click Here To Go Back To Homepage
      </a>
    </div>
  )
}
